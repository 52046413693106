function disable_margins_forms() {
  $("#dp_rule_margin_min").attr("disabled", "disabled");
  $("#dp_rule_margin_max").attr("disabled", "disabled");
  $("#dp_rule_quantity_less").attr("disabled", "disabled");
  $("#dp_rule_quantity_more").attr("disabled", "disabled");
  $("#dp_rule_margin_less").attr("disabled", "disabled");
  $("#dp_rule_margin_more").attr("disabled", "disabled");
}

function enable_margins_forms() {
  $("#dp_rule_margin_min").removeAttr("disabled");
  $("#dp_rule_margin_max").removeAttr("disabled");
  $("#dp_rule_quantity_less").removeAttr("disabled");
  $("#dp_rule_quantity_more").removeAttr("disabled");
  $("#dp_rule_margin_less").removeAttr("disabled");
  $("#dp_rule_margin_more").removeAttr("disabled");
}

function disable_categories_forms() {
  $(".form-dp-rule-category_id").attr("disabled", "disabled");
  $(".form-dp-rule-subcategory_id").attr("disabled", "disabled");
  $(".form-dp-rule-brand_id").attr("disabled", "disabled");
}

$(document).on(
  "change",
  "#dp_rule_margin_less, #dp_rule_margin_more",
  function () {
    if (
      $("#dp_rule_margin_more").val() != "" ||
      $("#dp_rule_margin_less").val() != ""
    ) {
      $("#dp_rule_quantity_less").attr("disabled", "disabled");
      $("#dp_rule_quantity_more").attr("disabled", "disabled");
      $("#dp_rule_lock_pvp").attr("disabled", "disabled");
      $("#dp_rule_locked").attr("disabled", "disabled");
    } else {
      $("#dp_rule_quantity_less").removeAttr("disabled");
      $("#dp_rule_quantity_more").removeAttr("disabled");
      $("#dp_rule_lock_pvp").removeAttr("disabled");
      $("#dp_rule_locked").removeAttr("disabled");
    }
  }
);

$(document).on(
  "change",
  "#dp_rule_quantity_less, #dp_rule_quantity_less",
  function () {
    if (
      $("#dp_rule_quantity_less").val() != "" ||
      $("#dp_rule_quantity_less").val() != ""
    ) {
      $("#dp_rule_margin_more").attr("disabled", "disabled");
      $("#dp_rule_margin_less").attr("disabled", "disabled");
      $("#dp_rule_lock_pvp").attr("disabled", "disabled");
      $("#dp_rule_locked").attr("disabled", "disabled");
    } else {
      $("#dp_rule_margin_more").removeAttr("disabled");
      $("#dp_rule_margin_less").removeAttr("disabled");
      $("#dp_rule_lock_pvp").removeAttr("disabled");
      $("#dp_rule_locked").removeAttr("disabled");
    }
  }
);

$(document).on(
  "change",
  ".form-dp-rule-category_id, .form-dp-rule-subcategory_id, .form-dp-rule-brand_id",
  function () {
    if (
      $(".form-dp-rule-category_id").val() != "" ||
      $(".form-dp-rule-subcategory_id").val() != "" ||
      $(".form-dp-rule-brand_id").val() != ""
    ) {
      $(".form-dp-rule-product_id").attr("disabled", "disabled");
    } else {
      $(".form-dp-rule-product_id").removeAttr("disabled");
    }
  }
);

$(document).on("change", ".form-dp-rule-product_id", function () {
  if ($(this).val() != "") {
    disable_categories_forms();
  } else {
    $(".form-dp-rule-category_id").removeAttr("disabled");
    $(".form-dp-rule-subcategory_id").removeAttr("disabled");
    $(".form-dp-rule-brand_id").removeAttr("disabled");
  }
});

$(document).on("change", "#dp_rule_locked", function () {
  if ($(this).is(':checked')) {
    disable_margins_forms();
    $("#dp_rule_lock_pvp").attr("disabled", "disabled");
  } else {
    enable_margins_forms();
    $("#dp_rule_lock_pvp").removeAttr("disabled");
  }
});

$(document).on("change", "#dp_rule_lock_pvp", function () {
  if ($(this).val() != "") {
    disable_margins_forms();
     $("#dp_rule_locked").attr("disabled", "disabled");
  } else {
    enable_margins_forms();
    $("#dp_rule_locked").removeAttr("disabled");
  }
});


var disabled_ready_forms = function () {
  if ($(".form-dp-rule-product_id").val() != "") {
    disable_categories_forms();
  }
  if (
    $(".form-dp-rule-category_id").val() != "" ||
    $(".form-dp-rule-subcategory_id").val() != "" ||
    $(".form-dp-rule-brand_id").val() != ""
  ) {
    $(".form-dp-rule-product_id").attr("disabled", "disabled");
  }
  if ($("#dp_rule_locked").is(":checked")) {
    disable_margins_forms();
    $("#dp_rule_lock_pvp").attr("disabled", "disabled");
  }
  if ($("#dp_rule_lock_pvp").val() != "") {
    disable_margins_forms();
  }
  if (
    $("#dp_rule_quantity_less").val() != "" ||
    $("#dp_rule_quantity_less").val() != ""
  ) {
    $("#dp_rule_margin_more").attr("disabled", "disabled");
    $("#dp_rule_margin_less").attr("disabled", "disabled");
  }
  if (
    $("#dp_rule_margin_more").val() != "" ||
    $("#dp_rule_margin_less").val() != ""
  ) {
    $("#dp_rule_quantity_less").attr("disabled", "disabled");
    $("#dp_rule_quantity_more").attr("disabled", "disabled");
  }
};

//$(disabled_ready_forms);
document.addEventListener("turbo:load", disabled_ready_forms);
document.addEventListener("turbo:frame-load", disabled_ready_forms);