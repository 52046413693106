$(document).on('change', '#file_type', function(){
    $.ajax({
        method: 'post',
        dataType: 'script',
        url: '/downloads/reload_info',
        data: { file_type: $("option:selected", this).val() }
    });
});

$(document).on('change', '#file_seller_type_id', function(){
    $.ajax({
        method: 'post',
        dataType: 'script',
        url: '/downloads/reload_sellers_search_form',
        data: { seller_type_id: $("option:selected", this).val() }
    });
});

$(document).on('change', '#file_seller_id', function(){
    $.ajax({
        method: 'post',
        dataType: 'script',
        url: '/downloads/reload_rankings_search_form',
        data: { seller_type_id: $("option:selected", this).val() }
    });
});