// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "core-js/stable";
import "regenerator-runtime/runtime";

import "../src/products";
import "../src/prices";
import "../src/downloads";
import "../src/dp";

require("@rails/ujs").start();
import * as Turbo from "@hotwired/turbo";
window.Turbo = Turbo;
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

import * as bootstrap from "bootstrap";
import "./menu-left.cleanui-custom";
import "./menu-right.cleanui-custom";
import "select2";
import "ion-rangeslider";
import "jqdoublescroll";
import "trix";
import "@rails/actiontext";
import Lightbox from "bs5-lightbox";
import "floatthead";
import langpl from "select2/src/js/select2/i18n/es";
global.toastr = require("toastr");
import "../src/password";

import Highcharts from "highcharts/highstock";
require("highcharts/modules/xrange")(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);
require("highcharts/modules/stock")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/series-label")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);
window.Highcharts = Highcharts;

import "../stylesheets/application";

import LocalTime from "../src/local_time.js";
window.LocalTime = LocalTime;
import "../src/pagy.js.erb";
import "../src/analytics.js.erb";
global.$ = jQuery;

["turbo:load", "turbo:frame-load"].forEach(function(evt) {
  document.addEventListener(evt, () => {
    let popoverTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    );
    let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl, {
        sanitize: false,
      });
    });

    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl);
    });

    document
      .querySelectorAll('[data-bs-toggle="lightbox"]')
      .forEach((el) => el.addEventListener("click", Lightbox.initialize));


    $(".js-range-slider").ionRangeSlider({
      skin: "round",
    });

    $(".table-responsive").doubleScroll({
      scrollCss: {
        "overflow-x": "auto",
        "overflow-y": "hidden",
        height: "10px",
      },
      resetOnWindowResize: true,
    });

    var toastElList = [].slice.call(document.querySelectorAll(".toast"));
    var toastList = toastElList.map(function (toastEl) {
      return new bootstrap.Toast(toastEl).show();
    });

    /*
    $(".table-thead-fixed").floatThead({
      top: $(".cat__top-bar").height(),
      position: "fixed",
      autoReflow: true,
      zIndex: 800,
      responsiveContainer: function ($table) {
        return $table.closest(".table-responsive");
      },
    });
    */
  });
});

$(document).on("shown.bs.tab", ".nav-pills > a", function (e) {
  $(this).siblings().removeClass("active");
});

$(document).on("show.bs.dropdown", ".nav-pills .dropdown", function (e) {
  $(this).siblings().find(".active").removeClass("active");
});

$(document).on("click", "*[data-href]", function (e) {
  window.open($(this).data("href"), "_blank", "noreferrer");
});

document.addEventListener("turbo:submit-start", function () {
  Turbo.navigator.delegate.adapter.showProgressBar();
});

$(document).on("turbo:before-cache", function () {
  $(".table-thead-fixed").each(function () {
    $(this).floatThead('destroy');
  });

  $(".js-range-slider").each(function() {
    $(this).data("ionRangeSlider").destroy();
  });

  $(".js-select2-form").each(function () {
    $(this).data("select2").destroy();
  });

  /*
  $(".table-thead-fixed").each(function () {
    $(this).data("floatThead").destroy();
  });
  */

  var toastElList = [].slice.call(document.querySelectorAll(".toast"));
  var toastList = toastElList.map(function (toastEl) {
    new bootstrap.Toast(toastEl).dispose();
  });
});