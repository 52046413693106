window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}

gtag('js', new Date());

document.addEventListener("turbo:load", function (event) {
    gtag('config', 'G-527V50WE6Y', {
        page_location: event.detail.url
    });
});

export default gtag