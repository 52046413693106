$(document).on('change', '#search_by_seller_type_id', function(){
    $.ajax({
        method: 'post',
        dataType: 'script',
        url: '/prices/reload_sellers_search_form',
        data: { seller_type_id: $("option:selected", this).val() }
    });
});

$(document).on('change', '#check-all', function(){
    $(".price-check-box").prop('checked', $(this).is(":checked"));
});

$(document).on('change', '.price-check-box', function(){
    $(".btn-selection").attr('disabled', !$(':checkbox.price-check-box').filter(':checked').length);
});

